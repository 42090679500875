<template>
  <div v-scroll="handleScroll">
    <!-- <NewAppCallToAction
      :show.sync="ctaDialogVisible"
      :is-new-project="ctaDialogIsNewProject"
    /> -->
    <div style="top: 15px; left: 15px; position: fixed; z-index: 5000;">
      <v-img
        src="/img/pathfinder-light-color.png"
        width="100"
        height="50"
        contain
      />
    </div>
    <div style="top: 15px; right: 15px; position: fixed; z-index: 5000;">
      <UserSettings />
    </div>
    <v-app-bar
      color="onyx"
      dark
      fixed
      flat
      height="80"
      :style="{ opacity: appBarOpacity }"
    >
      <v-spacer />
      <v-toolbar-title>My Projects</v-toolbar-title>
      <v-spacer />
      <!-- <UserSettings /> -->
    </v-app-bar>
    <ProjectsMap
      :projects="projects"
      :average-ytp="averageYtp"
    />
    <div style="position: relative; min-height: 100vh;">
      <v-container
        id="list"
        fluid
        style="position: absolute; padding-top: 0px; min-height: 100vh;"
        class="white"
      >
        <v-row
          class="justify-center"
        >
          <v-col
            cols="12"
            md="8"
            lg="10"
            xl="8"
          >
            <v-alert
              v-if="error"
              prominent
              type="error"
              class="mt-5"
            >
              Sorry, an error occured while loading your projects. We may be carrying out server maintenance, please try again in a few minutes.
            </v-alert>
            <v-alert
              v-if="!error && projects && projects.length === 0"
              type="success"
              width="500"
              icon="mdi-alert-circle-outline"
              class="mt-5 mx-auto"
              outlined
            >
              It looks like you haven't created any projects yet. Click the "New project" button to get started.
            </v-alert>
            <v-list v-if="!error && !projects">
              <v-skeleton-loader
                v-for="n in 3"
                :key="n"
                type="list-item-three-line"
                class="my-4 mx-auto px-0"
                width="430"
              />
            </v-list>
            <v-list v-else>
              <v-list-item
                v-for="project in projects"
                :key="project._id"
                class="my-4 mx-auto px-0"
                style="max-width: 600px;"
              >
                <ProjectCard
                  :project-id="project._id"
                  :title="project.title"
                  :type="project.type"
                  :creation-date="project.creationDate"
                  :description="project.description"
                  :image="project.image && project.image.content"
                  :is-legacy="project.isLegacy"
                  :years-to-positive="project.yearsToPositive"
                />
              </v-list-item>
              <div
                style="position: fixed; left: 50%; transform: translateX(-50%); bottom: calc(5vh + 10px); width: 400px; max-width: 85%; font-size: 18px; z-index: 5;"
              >
                <v-btn
                  color="onyx"
                  dark
                  elevation="0"
                  large
                  :style="{ opacity: newProjectButtonOpacity }"
                  tile
                  block
                  style="font-size: 18px;"
                  to="/projects/new"
                >
                  New project
                </v-btn>
                <v-btn
                  v-if="user.isAdmin && $vuetify.breakpoint.smAndUp"
                  color="shamrock"
                  class="mx-auto px-5 mb-3"
                  elevation="0"
                  dark
                  large
                  :style="{ opacity: newProjectButtonOpacity }"
                  tile
                  style="font-size: 18px; position: absolute; top: 0; left: calc(100% + 20px);"
                  to="/admin"
                >
                  Admin Zone
                </v-btn>
              </div>
            </v-list>
            <a
              v-if="!error"
              href="https://climatepositivedesign.com"
              target="_blank"
              class="mx-auto mt-5 mb-10 d-flex shamrock--text justify-center"
              style="max-width: 600px;"
            >
              <!-- <v-icon
                left
                color="shamrock"
              >
                mdi-web
              </v-icon> -->
              Learn more at climatepositivedesign.com
              <v-icon
                right
                color="shamrock"
              >
                mdi-open-in-new
              </v-icon>
            </a>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapMutations, createNamespacedHelpers } from 'vuex'
import ProjectCard from './ProjectCard'
import ProjectsMap from './ProjectsMap'
import UserSettings from '@/components/application/UserSettings'
// import NewAppCallToAction from '@/components/organisms/NewAppCallToAction'
import * as d3 from 'd3'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')

export default {
  name: 'MyProjects',
  components: {
    ProjectCard,
    ProjectsMap,
    UserSettings
    // NewAppCallToAction
  },
  data () {
    return {
      projects: null,
      error: null,
      averageYtp: 0,
      appBarOpacity: 0,
      newProjectButtonOpacity: 0,
      ctaDialogVisible: false,
      ctaDialogIsNewProject: false
    }
  },
  computed: {
    ...mapAuthGetters(['user'])
  },
  created () {
    this.fetchProjects()
    if (!this.$store.state.hasSeenPopupOnProjectsPage) {
      setTimeout(() => {
        this.ctaDialogVisible = true
        this.ctaDialogIsNewProject = false
        this.$store.commit('popupDisplayed')
      }, 3000)
    }
  },
  mounted () {
    this.handleScroll()
  },
  methods: {
    ...mapMutations(['showSnackbar']),
    handleScroll () {
      this.getAppBarOpacity()
      this.getNewProjectButtonOpacity()
    },
    getNewProjectButtonOpacity () {
      const snapPoint = window.innerHeight * 0.6
      if (window.innerHeight < snapPoint) {
        this.newProjectButtonOpacity = 0
      } else {
        const scale = d3.scaleLinear([snapPoint, snapPoint + 60], [0.3, 1])
        this.newProjectButtonOpacity = scale(window.scrollY)
      }
    },
    getAppBarOpacity () {
      const snapPoint = window.innerHeight * 0.6
      const scale = d3.scaleLinear([snapPoint - 200, snapPoint], [0, 1])
      this.appBarOpacity = scale(window.scrollY)
    },
    async fetchProjects () {
      try {
        const { data } = await this.$axios.get('/projects')
        this.projects = data.projects
        this.averageYtp = data.averageYtp
      } catch (err) {
        this.error = true
        throw err
      }
    }
  }
}
</script>

<style scoped>
.soon {
  position: fixed;
  bottom: 0;
  left: 0;
  margin-bottom: 0;
  width: 100%;
  border-radius: 0 !important;
  text-align: center;
}
</style>
